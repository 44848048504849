body {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #111;
  color: #fff;
  line-height: 1.6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.checks {
  list-style: none;
}

.checks li:before {
  content: '✓';
  padding: 0 5px 0 0;
  color:rgb(75, 235, 75);
  font-weight: bold;
}

.wrongs {
  list-style: none;
}

.wrongs li:before {
  content: '✕';
  padding: 0 5px 0 0;
  color:firebrick;
  font-weight: bold;
}

.price {
  list-style: none;
  font-size:x-large;
}

.price li:before {
  content: '₱';
  padding: 0 5px 0 0;
  color:rgb(255, 135, 88);
  font-weight: bold;
}